import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Cloud from "../cloud"

const useStyles = makeStyles(theme => ({
  about: {
    position: "relative",
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
  },
  heading: {
    marginBottom: theme.spacing(5),
    "& + p": {
      marginBottom: theme.spacing(5),
    },
  },
  paragraph: {
    marginBottom: theme.spacing(5),
    "& + h2": {
      marginTop: theme.spacing(5),
    },
  },
  gridContainer: {
    justifyContent: "space-between",
    "& + div": {
      marginTop: theme.spacing(5),
    },
    "&:first-child": {
      [theme.breakpoints.down("sm")]: {
        flexDirection: 'column-reverse'
      }
    }
  },
  image: {
    maxWidth: theme.spacing(52.5),
    maxHeight: theme.spacing(52.5),
    [theme.breakpoints.down("sm")]: {
      left: '6vw',
      position: "relative",
      transform: "scale(0.9)",
    }
  },
  gatsbyImage: {
    maxHeight: "100%",
    "& img": {
      objectFit: "contain !important",
    },
  },
  paint: {
    top: "-40px",
    right: "-72px",
    position: "absolute",
  },
  cloud: {},
}))

const About = ({ data }) => {
  const classes = useStyles()

  return (
    <Box component="section" className={classes.about}>
      <Container maxWidth="md">
        <Grid container spacing={5} className={classes.gridContainer}>
          <Grid item md={6} sm={12}>
            <Typography component="h2" variant="h1" className={classes.heading}>
              {data.aboutTitle}
            </Typography>
            <Typography
              component="div"
              variant="body1"
              className={classes.paragraph}
              dangerouslySetInnerHTML={{ __html: data.aboutDescription }}
            />
          </Grid>
          <Grid item md={6} sm={12} className={classes.image}>
            <GatsbyImage
              alt="Zdjęcie sekcja o nas"
              image={getImage(data.image)}
              className={classes.gatsbyImage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} className={classes.gridContainer}>
          <Grid item md={6} sm={12}>
            <Typography component="h2" variant="h1" className={classes.heading}>
              {data.offerTitle}
            </Typography>
            <Typography
              component="div"
              variant="body1"
              className={classes.paragraph}
              dangerouslySetInnerHTML={{ __html: data.offerDescription }}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography component="h2" variant="h1" className={classes.heading}>
              {data.whatWeOrganizeTitle}
            </Typography>
            <Typography
              component="div"
              variant="body1"
              className={classes.paragraph}
              dangerouslySetInnerHTML={{
                __html: data.whatWeOrganizeDescription,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Cloud position="left" />
    </Box>
  )
}

export default About
