import React from "react"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
// import { makeStyles } from "@material-ui/core/styles"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

// const useStyles = makeStyles(theme => ({}))

export default function Hero() {
  // const classes = useStyles()

  const { datoCmsHomePage } = useStaticQuery(graphql`
    query {
      datoCmsHomePage {
        desktopImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE, width: 1920, height: 1067)
        }
        mobileImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const mobileImage = getImage(datoCmsHomePage.mobileImage)
  const desktopImage = getImage(datoCmsHomePage.desktopImage)

  return (
    <Box component="section">
      <Hidden xsDown>
        <GatsbyImage alt="Banner na stronie głównej" image={desktopImage}/>
      </Hidden>
      <Hidden smUp>
        <GatsbyImage alt="Banner na stronie głównej" image={mobileImage}/>
      </Hidden>
    </Box>
  )
}
