import React, { Suspense, lazy } from 'react';
import NoSsr from '@material-ui/core/NoSsr';

import { graphql } from "gatsby"

import Seo from "../components/seo"
import Hero from "../components/hero"
import About from "../components/aboutSection"
import Layout from "../components/layout"
import Regulations from "../components/regulations"

const Slick = lazy(() => import('../components/slick'));

const IndexPage = ({ data, location }) => {
  const {
    aboutTitle,
    aboutDescription,
    offerTitle,
    offerDescription,
    whatWeOrganizeTitle,
    whatWeOrganizeDescription,
    image,
    seo
  } = data && data.datoCmsHomePage

  const { nodes = [] } = data && data.allDatoCmsNews && data.allDatoCmsNews

  const aboutData = {
    aboutTitle,
    aboutDescription,
    offerTitle,
    offerDescription,
    whatWeOrganizeTitle,
    whatWeOrganizeDescription,
    image,
  }

  return (
    <Layout location={location}>
      <Seo title={seo.title} description={seo.description} />
      <Hero />
      <NoSsr>
        <Suspense fallback={null}>
          <Slick data={nodes} />
        </Suspense>
      </NoSsr>
      <About data={aboutData} />
      <Regulations />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    datoCmsHomePage {
      desktopImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
      mobileImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
      aboutTitle
      aboutDescription
      offerTitle
      offerDescription
      whatWeOrganizeTitle
      whatWeOrganizeDescription
      image {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
      }
      seo {
        title
        description
      }
    }
    allDatoCmsNews(sort: {order: DESC, fields: date}, limit: 3) {
      nodes {
        id
        title
        slug
        date
        teaser
        imageTeaser {
          gatsbyImageData(
            placeholder: BLURRED,
            layout: CONSTRAINED,
            width: 250,
            height: 250
          )
        }
      }
    }
  }
`

export default IndexPage
