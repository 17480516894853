import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import MuiButton from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"

import Cloud from "../cloud"

const Button = withStyles(theme => ({
  contained: {
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    boxShadow: "none",
    marginTop: 16,
    borderRadius: 50,
    backgroundColor: "hsl(178deg 72% 67%)",

    "&:hover": {
      boxShadow: "none",
      backgroundColor: "hsl(178deg 72% 77%)",
    },
  },
}))(MuiButton)

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.warning.main,
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`,
    background: "none",
  },
  image: {
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      left: '-6vw',
      position: "relative",
      transform: "scale(0.9)",
    }
  },
  title: {
    marginBottom: theme.spacing(2.5),
    textTransform: "uppercase",
  },
  description: {
    "& a": {
      textDecoration: 'none',
      "&:hover": {
        textDecoration: 'underline'
      },
      "&:hover:active": {
        textDecoration: 'underline'
      },
    }
  },
  paintIcon: {
    top: "-35px",
    left: 21,
    position: "absolute",
  },
  gridItemImageContainer: {
    top: 20,
  },
}))

export default function Regulations() {
  const classes = useStyles()

  const { datoCmsHomePage } = useStaticQuery(graphql`
    query RegulaltionsQuery {
      datoCmsHomePage {
        regulationImage {
          gatsbyImageData(layout: CONSTRAINED, placeholder: NONE)
        }
        regulationSection {
          id
          title
          description
        }
      }
    }
  `)

  const regulationSection =
    (datoCmsHomePage && datoCmsHomePage.regulationSection) || []

  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Grid container spacing={10}>
          <Grid item md={6} sm={12} className={classes.gridItemImageContainer}>
            <GatsbyImage
              alt="Zdjęcie sekcja regulaminy"
              image={getImage(datoCmsHomePage.regulationImage)}
              className={classes.image}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            {regulationSection.map(({ id, title, description }, index) => {
              return (
                <React.Fragment key={id}>
                  <Typography
                    variant="h1"
                    component="p"
                    className={classes.title}
                  >
                    {title}
                  </Typography>
                  <Typography
                    component="div"
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: description }}
                    className={classes.description}
                  />
                  {index < regulationSection.length - 1 && (
                    <Divider className={classes.divider} />
                  )}
                </React.Fragment>
              )
            })}
            <Button variant="contained" href="/wnetrze/">
              <Typography component="div" color="textPrimary" variant="h4">
                więcej
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Cloud position="right" />
    </Box>
  )
}
